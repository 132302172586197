import {
    Box,
    Text,
    Card,
    Select,
    Flex,
    SimpleGrid,
    CardBody,
    ChakraProvider,
    Center,
    Link,
    useColorModeValue,
} from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';
import { database } from "./utils/config.js";
import { getDatabase, ref, onValue } from "firebase/database";
import ReactApexChart from 'react-apexcharts';
import Papa from 'papaparse';
import adm0_codes from './data/statsOptions/adm0_codes.csv'

export default function StatsDashboardApex() {
    const [chartData, setChartData] = useState([]);
    const [biomeData, setBiomeData] = useState([]);
    const [ecoData, setEcoData] = useState([]);
    const [landcoverData, setLandCoverData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('Angola');
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('01');
    const [monthTitle, setMonthTitle] = useState('Jan');
    const [selectedYear, setSelectedYear] = useState('2023');


    useEffect(() => {
        changeHandler();
    }, []); // Empty dependency array to run only once when the component mounts


    const changeHandler = async () => {
        try {
            const response = await fetch(adm0_codes);
            const csvData = await response.text();

            const results = Papa.parse(csvData, {
                header: true,
                skipEmptyLines: true,
            });

            const countries = results.data.map((row) => row["ADM0_NAME"]);
            const filteredCountries = countries.filter((country) => country);
            const options = filteredCountries.map((country) => ({
                value: country,
                label: country,
            }));
            setCountryOptions(options);
        } catch (error) {
            console.error('Error fetching or parsing CSV:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dbRef = getDatabase();
                const dataRef = ref(dbRef, '/'); // Adjust the path as needed
                onValue(dataRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        // Filter data for Czech Republic, April 2022, and ecoregions
                        const filteredData = data[selectedCountry]?.[selectedYear + '-' + selectedMonth]?.['biome'];
                        // Filter data for Czech Republic, April 2022, and ecoregions
                        const filteredEcoData = data[selectedCountry]?.[selectedYear + '-' + selectedMonth]?.['ecoregion'];
                        // Filter data for Czech Republic, April 2022, and biomes
                        const filteredBiomeData = data[selectedCountry]?.[selectedYear + '-' + selectedMonth]?.['biome'];
                        // Filter data for Czech Republic, April 2022, and landcover
                        const filteredLandCoverData = data[selectedCountry]?.[selectedYear + '-' + selectedMonth]?.['landcover'];

                        if (filteredData) {
                            const dataArray = Object.values(filteredData);
                            // Convert object to array of objects
                            setChartData(dataArray);
                        }
                        if (filteredEcoData) {
                            // Convert object to array of objects
                            const dataArray = Object.values(filteredEcoData);
                            setEcoData(dataArray);
                        }
                        if (filteredBiomeData) {
                            // Convert object to array of objects
                            const dataArray = Object.values(filteredBiomeData);
                            setBiomeData(dataArray);
                        }
                        if (filteredLandCoverData) {
                            // Convert object to array of objects
                            const dataArray = Object.values(filteredLandCoverData);
                            setLandCoverData(dataArray);
                        }
                    }
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [selectedCountry, selectedMonth, selectedYear]);




    return (
        <ChakraProvider>
            <>
                <Card borderRadius={0} colorScheme='Blue'>
                    <CardBody p={1}>
                        <SimpleGrid
                            columns={{ base: 1, md: 3 }}
                            spacing={{ base: 2, md: 6 }}
                            p={1}
                            px={6}
                        >
                            <Flex alignItems="center">
                                <Text fontSize="sm" pr={1}>
                                    Month:
                                </Text>
                                <Select size="sm" onChange={(e) => { setSelectedMonth(e.target.value.slice(-2)); setMonthTitle(e.target.value.slice(0, 3)); }}>
                                    <option>Dec - 12</option>
                                    <option>Nov - 11</option>
                                    <option>Oct - 10</option>
                                    <option>Sept - 09</option>
                                    <option>Aug - 08</option>
                                    <option>Jul - 07</option>
                                    <option>Jun - 06</option>
                                    <option>May - 05</option>
                                    <option>Apr - 04</option>
                                    <option>Mar - 03</option>
                                    <option>Feb - 02</option>
                                    <option selected>Jan - 01</option>
                                </Select>
                            </Flex>
                            <Flex alignItems="center">
                                <Text fontSize="sm" pr={1}>
                                    Year:
                                </Text>
                                <Select size="sm" onChange={(e) => { setSelectedYear(e.target.value) }}>
                                    <option value='2023' selected>2023</option>
                                    <option value='2022'>2022</option>
                                    <option value='2021'>2021</option>
                                    <option value='2020'>2020</option>
                                </Select>
                            </Flex>
                            <Flex alignItems="center">
                                <Text fontSize="sm" pr={1}>
                                    Country:
                                </Text>
                                <Select placeholder="Select a country" onChange={(e) => setSelectedCountry(e.target.value)}>
                                    {countryOptions.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>

                            </Flex>
                        </SimpleGrid>
                    </CardBody>
                </Card>
                <SimpleGrid
                    columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
                    spacing={6}
                >
                    <Center py={6} px="50px">
                        <Box
                            width={{ base: 'full', md: 'auto', sm: 'auto', xs: 'auto' }}
                            height={'570px'}

                            minW={'100%'}
                            bg={useColorModeValue('gray.200', 'gray.900')}
                            // boxShadow={'2xl'}
                            rounded={'lg'}
                            p={6}
                            textAlign={'center'}
                        >

                            <ReactApexChart
                                options={{
                                    chart: {
                                        toolbar: {
                                            show: true,
                                            offsetX: 0,
                                            offsetY: 0,
                                            tools: {
                                                download: true,
                                                selection: true,
                                            },
                                            export: {
                                                csv: {
                                                    filename: selectedCountry + '_' + monthTitle + '_' + selectedYear + '_ecoregion',
                                                    columnDelimiter: ',',
                                                    headerCategory: 'eco_name',
                                                    headerValue: 'eco_burnedarea'
                                                },
                                                svg: {
                                                    filename: selectedCountry + '_' + monthTitle + '_2022',
                                                },
                                                png: {
                                                    filename: selectedCountry + '_' + monthTitle + '_2022',
                                                }
                                            },
                                            autoSelected: 'zoom'
                                        },
                                    },
                                    plotOptions: {
                                        bar: {
                                            barHeight: '100%',
                                            distributed: false,
                                            horizontal: true,
                                            dataLabels: {
                                                position: 'bottom',
                                            },
                                        },
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        textAnchor: 'start',
                                        style: {
                                            colors: ['#000']
                                        },
                                        formatter: function (val, opt) {
                                            const ecoName = ecoData[opt.dataPointIndex].eco_name;

                                            return ecoName
                                        },


                                    },
                                    stroke: {
                                        width: 1,
                                        colors: ['#fff']
                                    },
                                    xaxis: {
                                        categories: ecoData.map(item => item.eco_name),
                                        tickAmount: 5,
                                        title: {
                                            text: 'Burned Area (ha)',
                                            style: {
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Arial, sans-serif',
                                                color: '#333'
                                            }
                                        }


                                    },
                                    yaxis: {
                                        labels: {
                                            show: false
                                        },
                                        title: {
                                            text: 'RESOLVE Ecoregion Name',
                                            style: {
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Arial, sans-serif',
                                                color: '#333'
                                            }
                                        }
                                    },
                                    title: {
                                        text: selectedCountry + ' ' + monthTitle + '. ' + selectedYear + ' Burn Area Estimates by Ecoregion',
                                        align: 'center',
                                        floating: true,
                                    },
                                    tooltip: {
                                        theme: 'dark',
                                        x: {
                                            show: false
                                        },
                                        y: {
                                            formatter: function (val) {
                                                return val.toLocaleString();
                                            }
                                        }
                                    },
                                    legend: {
                                        show: false // Setting legend option to false to remove the legend
                                    },
                                    colors: ['#805939'],




                                }}
                                series={[{
                                    name: 'burned area (ha)',
                                    data: ecoData.map(item => parseFloat(item.eco_burnedarea).toFixed(2)),
                                }]}
                                type="bar"
                                height={500}
                            />
                            <Box paddingRight={2} textAlign="right" fontSize="xs">
                                Ecoregion Source:{' '}
                                <Link href="https://ecoregions.appspot.com/" isExternal>
                                RESOLVE
                                </Link>
                            </Box>
                        </Box>
                    </Center>
                    <Center py={6} px="50px">
                        <Box
                            width={{ base: 'full', md: 'auto', sm: 'auto', xs: 'auto' }}
                            height={'570px'}

                            minW={'100%'}
                            bg={useColorModeValue('gray.200', 'gray.900')}
                            // boxShadow={'2xl'}
                            rounded={'lg'}
                            p={6}
                            textAlign={'center'}
                        >


                            <ReactApexChart
                                options={{
                                    chart: {
                                        toolbar: {
                                            show: true,
                                            offsetX: 0,
                                            offsetY: 0,
                                            tools: {
                                                download: true,
                                                selection: true,
                                                zoom: true,
                                                zoomin: true,
                                                zoomout: true,
                                                pan: true,
                                            },
                                            export: {
                                                csv: {
                                                    filename: selectedCountry + '_' + monthTitle + '_' + selectedYear + '_biome',
                                                    columnDelimiter: ',',
                                                    headerCategory: 'biome_name',
                                                    headerValue: 'biome_burnedarea'
                                                },
                                                svg: {
                                                    filename: selectedCountry + '_' + monthTitle + '_' + selectedYear,
                                                },
                                                png: {
                                                    filename: selectedCountry + '_' + monthTitle + '_' + selectedYear,
                                                }
                                            },
                                            autoSelected: 'zoom'
                                        },
                                    },
                                    plotOptions: {
                                        bar: {
                                            barHeight: '100%',
                                            distributed: false,
                                            horizontal: true,
                                            dataLabels: {
                                                position: 'bottom',
                                            },
                                        }
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        textAnchor: 'start',
                                        style: {
                                            colors: ['#000']
                                        },
                                        formatter: function (val, opt) {
                                            const biomeName = biomeData[opt.dataPointIndex].biome_name;
                                            const formattedValue = parseFloat(val).toFixed(0);
                                            // return biomeName + ":  " + formattedValue;
                                            return biomeName
                                        },
                                    },
                                    stroke: {
                                        width: 1,
                                        colors: ['#fff']
                                    },
                                    xaxis: {
                                        categories: biomeData.map(item => item.biome_name),
                                        tickAmount: 5,
                                        title: {
                                            text: 'Burned Area (ha)',
                                            style: {
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Arial, sans-serif',
                                                color: '#333'
                                            }
                                        }


                                    },
                                    yaxis: {
                                        labels: {
                                            show: false
                                        },
                                        title: {
                                            text: 'RESOLVE Biome Name',
                                            style: {
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Arial, sans-serif',
                                                color: '#333'
                                            }
                                        }
                                    },
                                    title: {
                                        text: selectedCountry + ' ' + monthTitle + '. ' + selectedYear + ' Burn Area Estimates by Biome',
                                        align: 'center',
                                        floating: true,
                                    },
                                    // subtitle: {
                                    //     text: 'Category Names as DataLabels inside bars',
                                    //     align: 'center',
                                    // },
                                    tooltip: {
                                        theme: 'dark',
                                        x: {
                                            show: false
                                        },
                                        y: {
                                            formatter: function (val) {
                                                return val.toLocaleString();
                                            }
                                        }
                                    },
                                    legend: {
                                        show: false // Setting legend option to false to remove the legend
                                    },
                                    colors: ['#d70000']

                                }}
                                series={[{
                                    name: 'burned area (ha)',
                                    data: biomeData.map(item => parseFloat(item.biome_burnedarea).toFixed(2)),
                                }]}
                                type="bar"
                                height={500}
                            />
                            <Box paddingRight={2} textAlign="right" fontSize="xs">
                                Biome Source:{' '}
                                <Link href="https://ecoregions.appspot.com/" isExternal>
                                RESOLVE
                                </Link>
                            </Box>

                        </Box>
                    </Center>
                    <Center py={6} px="50px">
                        <Box
                            width={{ base: 'full', md: 'auto', sm: 'auto', xs: 'auto' }}
                            height={'570px'}

                            minW={'100%'}
                            bg={useColorModeValue('gray.200', 'gray.900')}
                            // boxShadow={'2xl'}
                            rounded={'lg'}
                            p={6}
                            textAlign={'center'}
                        >


                            <ReactApexChart
                                options={{
                                    chart: {
                                        toolbar: {
                                            show: true,
                                            offsetX: 0,
                                            offsetY: 0,
                                            tools: {
                                                download: true,
                                                selection: true,
                                                zoom: true,
                                                zoomin: true,
                                                zoomout: true,
                                                pan: true,
                                            },
                                            export: {
                                                csv: {
                                                    filename: selectedCountry + '_' + monthTitle + '_' + selectedYear + '_landcover',
                                                    columnDelimiter: ',',
                                                    headerCategory: 'igbp_name',
                                                    headerValue: 'landcover_burnedarea'
                                                },
                                                svg: {
                                                    filename: selectedCountry + '_' + monthTitle + '_' + selectedYear,
                                                },
                                                png: {
                                                    filename: selectedCountry + '_' + monthTitle + '_' + selectedYear,
                                                }
                                            },
                                            autoSelected: 'zoom'
                                        },
                                    },
                                    plotOptions: {
                                        bar: {
                                            barHeight: '100%',
                                            distributed: false,
                                            horizontal: true,
                                            dataLabels: {
                                                position: 'bottom',
                                            },
                                        }
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        textAnchor: 'start',
                                        style: {
                                            colors: ['#000']
                                        },
                                        formatter: function (val, opt) {
                                            const lcName = landcoverData[opt.dataPointIndex].igbp_nam;
                                            const formattedValue = parseFloat(val).toFixed(0);
                                            // return biomeName + ":  " + formattedValue;
                                            return lcName
                                        },
                                    },
                                    stroke: {
                                        width: 1,
                                        colors: ['#fff']
                                    },
                                    xaxis: {
                                        categories: landcoverData.map(item => item.igbp_nam),
                                        tickAmount: 5,
                                        title: {
                                            text: 'Burned Area (ha)',
                                            style: {
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Arial, sans-serif',
                                                color: '#333'
                                            }
                                        }


                                    },
                                    yaxis: {
                                        labels: {
                                            show: false
                                        },
                                        title: {
                                            text: 'IGBP Land Cover Class',
                                            style: {
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Arial, sans-serif',
                                                color: '#333'
                                            }
                                        }
                                    },
                                    title: {
                                        text: selectedCountry + ' ' + monthTitle + '. ' + selectedYear + ' Burn Area Estimates by Land Cover',
                                        align: 'center',
                                        floating: true,
                                    },
                                    // subtitle: {
                                    //     text: 'Category Names as DataLabels inside bars',
                                    //     align: 'center',
                                    // },
                                    tooltip: {
                                        theme: 'dark',
                                        x: {
                                            show: false
                                        },
                                        y: {
                                            formatter: function (val) {
                                                return val.toLocaleString();
                                            }
                                        },

                                    },
                                    legend: {
                                        show: false // Setting legend option to false to remove the legend
                                    },
                                    colors: ['#ed8936']

                                }}
                                series={[{
                                    name: 'burned area (ha)',
                                    data: landcoverData.map(item => parseFloat(item.landcover_burnedarea).toFixed(2)),
                                }]}
                                type="bar"
                                height={500}
                            />

                            <Box paddingRight={2} textAlign="right" fontSize="xs">
                                Land Cover Source:{' '}
                                <Link href="https://lpdaac.usgs.gov/products/mcd12q1v061/" isExternal>
                                MCD12Q1v061 IGBP
                                </Link>
                            </Box>
                        </Box>

                    </Center>
                </SimpleGrid>

            </>
        </ChakraProvider>
    );
}
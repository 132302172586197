import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
//import { ColorModeSwitcher } from './pages/components/ColorModeSwitcher';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Navbar from './pages/components/Navbar';
import DatasetPage from './pages/DatasetPage';
import TeamPage from './pages/TeamPage';
import PublicationsPage from './pages/PublicationsPage';
import StatsPage from './pages/StatsPage';
import VIIRSPage from './pages/VIIRSPage';
import VIIRSAFPage from './pages/VIIRSAFPage';
import ArchivePage from './pages/ArchivePage';
import VIIRSBAPage from './pages/VIIRSBAPage';
// import TeamMemberTemplate from './pages/components/team-pages/TeamMemberTemplate';
import TeamMemberPage from './pages/TeamMemberPage';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/datasets" element={<DatasetPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/publications" element={<PublicationsPage />} />
        <Route path="/stats" element={<StatsPage />} />
        <Route path="/viirs" element={<VIIRSPage/>} />
        <Route path="/viirs-af" element={<VIIRSAFPage/>} />
        <Route path="/viirs-ba" element={<VIIRSBAPage/>} />
        <Route path="/archive" element={<ArchivePage />} />
        <Route path="/team/template" element={<TeamMemberPage />} />
      </Routes>
    </Router>
  );
}

export default App;

"use client";

import {
  Box,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
  Link,
  ChakraProvider,
} from "@chakra-ui/react";
import { useRef } from "react";
import GridListWith from "./team-pages-components/multiCardTemplate";
import avatar from "../../components/data/img/avatar.png";

export default function TeamMemberTemplate() {
  // Refs for scrolling
  const sectionRef = useRef(null);
  const projectDetailsRef = useRef(null);
  const otherDetailsRef = useRef(null);
  const aboutRef = useRef(null);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <ChakraProvider>
      <Flex h="100vh" px={10}>
        {/* Left Column */}
        <Box
          w={{ base: "100%", lg: "30%" }}
          position={{ base: "static", lg: "sticky" }}
          top={0}
          h="100vh"
          overflow="hidden"
          p={4}

        >
          <Stack spacing={6}>
            <Flex>
              <Image
                rounded={"md"}
                alt={"product image"}
                src={avatar}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={{ base: "100%", sm: "300px", lg: "400px" }}
              />
            </Flex>
            <VStack align="start" spacing={4}>
                <Button
                    onClick={() => scrollToSection(aboutRef)}
                    colorScheme="blue"
                    variant="link"  
                >
                    About
                </Button>
              <Button
                onClick={() => scrollToSection(sectionRef)}
                colorScheme="blue"
                variant="link"
              >
                Section
              </Button>
              <Button
                onClick={() => scrollToSection(projectDetailsRef)}
                colorScheme="blue"
                variant="link"
              >
                Projects
              </Button>
              <Button
                onClick={() => scrollToSection(otherDetailsRef)}
                colorScheme="blue"
                variant="link"
              >
                Other
              </Button>
            </VStack>
          </Stack>
        </Box>

        {/* Right Column */}
        <Box
          w={{ base: "100%", lg: "70%" }}
          h="100vh"
          overflowY="auto"
          p={4}
          sx={{
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "transparent",
            },
            "&:hover::-webkit-scrollbar": {
              width: "8px",
            },
            "&:hover::-webkit-scrollbar-thumb": {
              background: "orange.200",
              borderRadius: "4px",
            },
          }}
        >
          <Stack spacing={10}>
            <Box as={"header"} ref={aboutRef}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
              >
                Name Here
              </Heading>
              <Text
                color={useColorModeValue("gray.900", "gray.400")}
                fontWeight={300}
                fontSize={"2xl"}
              >
                title
              </Text>
            </Box>

            <Stack
              spacing={6}
              direction={"column"}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                />
              }
            >
              <VStack spacing={6}>
                <Text
                  color={useColorModeValue("gray.500", "gray.400")}
                  fontSize={"2xl"}
                  fontWeight={"300"}
                >
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore.
                </Text>
                <Text fontSize={"lg"}>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                  aliquid amet at delectus doloribus dolorum expedita hic, ipsum
                  maxime modi nam officiis porro, quae, quisquam quos
                  reprehenderit velit? Natus, totam.
                </Text>
              </VStack>

              {/* Section */}
              <Box ref={sectionRef}>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={useColorModeValue("yellow.500", "yellow.300")}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={4}
                >
                  Section
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                  <List spacing={2}>
                    <ListItem>Lorem ipsum</ListItem>
                    <ListItem>Odor amet</ListItem>
                    <ListItem>Consectetuer</ListItem>
                  </List>
                  <List spacing={2}>
                    <ListItem>Adipiscing</ListItem>
                    <ListItem>Elit</ListItem>
                    <ListItem>Nunc aenean</ListItem>
                  </List>
                </SimpleGrid>
              </Box>

              {/* Projects Section */}
              <Box ref={projectDetailsRef}>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={useColorModeValue("yellow.500", "yellow.300")}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={4}
                >
                  Projects
                </Text>
                <List spacing={2}>
                  <ListItem>
                    <Text as={"span"} fontWeight={"bold"}>
                    Arcu consequat:
                    </Text>{" "}
                    adipiscing lacus
                  </ListItem>
                  <ListItem>
                    <Text as={"span"} fontWeight={"bold"}>
                    Turpis ligula:
                    </Text>{" "}
                    inceptos pellentesque auctor
                  </ListItem>
                  <ListItem>
                    <Text as={"span"} fontWeight={"bold"}>
                    Erat odio:
                    </Text>{" "}
                    Massa curabitur litora accumsan
                  </ListItem>
                </List>
              </Box>
              <Box ref={otherDetailsRef}>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={useColorModeValue("yellow.500", "yellow.300")}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={4}
                >
                  Other
                </Text>
                <GridListWith />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </ChakraProvider>
  );
}

import React from 'react';
import Footer from './components/Footer.js';
import DatasetHeader from './components/header/DatasetHeader.js';
import ProductGrid from './components/ProductGrid.js';
import DatasetCardHolder from './components/DatasetCardHolder.js';
import DataGridHeader from './components/header/DataGridHeader.js';
import ScrollTooltip from './components/Tooltip.js';
function DatasetPage() {
  return (
    <div
      sx={{
        '& .scrollDiv': {
          height: 'auto',
          maxHeight: '100%',
          overflow: 'auto',
        },
      }}
    >
      <DatasetHeader />
      <ScrollTooltip />
      <DatasetCardHolder />
      <DataGridHeader />
      <ProductGrid />
      <br />
      <Footer />
    </div>
  );
}

export default DatasetPage;

import { Box, Typography, Button, Chip, Stack } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { productItems } from './data/ProductData.js';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LaunchIcon from '@mui/icons-material/Launch';
import * as React from 'react';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

// //const tagsSortComparator = (tags1, tags2) => {
//   return tags1.length - tags2.length
// }

// //const tagsFilterOperators = getGridSingleSelectOperators()
//   .filter(operator => operator.value === "isAnyOf")
//   .map(operator => {
//     const newOperator = { ...operator }
//     const newGetApplyFilterFn = (filterItem, column) => {
//       return params => {
//         let isOk = true
//         filterItem?.value?.forEach(fv => {
//           isOk = isOk && params.value.includes(fv)
//         })
//         return isOk
//       }
//     }
//     newOperator.getApplyFilterFn = newGetApplyFilterFn
//     return newOperator
//   })

const getColorForTag = (tag) => {

  const tagColors = {
    'Terra': 'secondary',
    'Aqua': 'primary',
    'Burned Area': 'error',
    'Cropland': 'warning',
    'Vector': 'info',
    'Other': 'default',
    // Add more mappings as needed
  };

  return tagColors[tag] || 'default'; // Default to 'default' if the tag is not in the mapping
};

const ProductGrid = () => {
  const columns = [
    { field: 'product', headerName: 'Product', width: 100 },
    {
      field: 'version',
      headerName: 'Version',
      minwidth: 50,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'mission',
      headerName: 'Mission',
      width: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 300,
      type: 'singleSelect',
      renderCell: params => (
        <Stack direction="row" spacing={0.15}>
          {params.row.tags.map(tag => (
            <Chip label={tag} color={getColorForTag(tag)} />
          ))}
        </Stack>
      ),
    },
    {
      field: 'userguidelink',
      headerName: 'User Guide Link',
      flex: 1,
    },
    {
      field: 'userguide',
      headerName: 'User Guide',
      width: 100,
      renderCell: ({ row: { userguide, userguidelink } }) => {
        // Check if the link is an external URL (starts with http or https)
        const isExternalLink = userguidelink.startsWith('http');
        return (
          <Button
            href={isExternalLink ? userguidelink : `${process.env.PUBLIC_URL}/pdfs/${userguidelink}`}
            target={isExternalLink ? '_blank' : '_self'} // Open external links in a new tab
            download={!isExternalLink} // Only set download attribute for local PDFs
          >
            {userguide === 'link' && <LinkIcon />}
            {userguide === 'unavailable' && <LinkOffIcon />}
            {userguide === 'userguide' && <LaunchIcon />}
            <Typography color="secondary" sx={{ ml: '5px' }}>
              {userguide}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: 'lpdaaclink',
      headerName: 'lpdaac Link',
      flex: 1,
    },
    {
      field: 'link',
      headerName: 'Action',
      width: 100,
      renderCell: ({ row: { link, lpdaaclink } }) => {
        return (
          <Button href={lpdaaclink} target={lpdaaclink} color="primary">
            {link === 'link' && <LinkIcon />}
            {link === 'unavailable' && <LinkOffIcon />}
            {link === 'access data' && <LaunchIcon />}
            <Typography color="secondary" sx={{ ml: '5px' }}>
              {link}
            </Typography>
          </Button>
        );
      },
    },
  ];

  return (
    <Box m="20px" px={4}>
      <DataGrid
        columnVisibilityModel={{ lpdaaclink: false, userguidelink: false }}
        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}
        rows={productItems}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              debounceMs: 500,
            },
          },
        }}
      />
    </Box>
  );
};

export default ProductGrid;

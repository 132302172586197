import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  IconButton,
  Grid,
  GridItem,
  useColorModeValue,
  ChakraProvider,
  theme,
  Icon,
  LinkOverlay,
  LinkBox,
  SimpleGrid,
} from '@chakra-ui/react';

import { FaUniversity, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter} from 'react-icons/fa6';
import roy from './data/img/roy.jpg';
import wilfrid from './data/img/wilfrid.jpg';
import luigi from './data/img/luigi.jpg';
import justice from './data/img/justice.jpg';

export default function AdjunctTeamCard() {
  return (
    <ChakraProvider theme={theme}>
      <SimpleGrid
        columns={{ sm: 2, md: 3 }}
        minChildWidth={'350px'}
        spacing={6}
      >
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={justice}
              alt={'Christopher Justice'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Christopher Justice
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Distinguished University Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Justice's current research is on land cover and land use
              change, land observations and data products, global agricultural
              monitoring, and their associated information technology and
              decision support systems.
            </Text>
            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://geog.umd.edu/facultyprofile/justice/chris">
                  <IconButton
                    aria-label="Justice UMD website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Justice linkedin"
                size="lg"
                icon={<FaLinkedinIn />}
                isDisabled="true"
              />
              <IconButton
                aria-label="Justice twitter"
                size="lg"
                icon={<FaXTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>

        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={luigi}
              alt={'Luigi Boschetti'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Luigi Boschetti
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Boschetti's research interests include global fire monitoring
              & fire regimes, biomass burning emission estimations, and global
              environmental remote sensing applications.
            </Text>
            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://www.uidaho.edu/cnr/faculty/boschetti">
                  <IconButton
                    aria-label="Boschetti Idaho website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Boschetti linkedin"
                size="lg"
                icon={<FaLinkedinIn />}
                isDisabled="true"
              />
              <IconButton
                aria-label="Boschetti twitter"
                size="lg"
                icon={<FaXTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={roy}
              alt={'David Roy'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. David Roy
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Professor
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            >
              Dr. Roy's research interests include development of remote sensing
              and advanced computing methods to integrate/fuse satellite sensor
              data to map and characterize terrestrial global change & fire
              remote sensing.
            </Text>
            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://geo.msu.edu/directory/roy-david-p.html">
                  <IconButton
                    aria-label="Roy website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Roy linkedin"
                size="lg"
                icon={<FaLinkedinIn />}
                isDisabled="true"
              />
              <IconButton
                aria-label="Roy twitter"
                size="lg"
                icon={<FaXTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
        <Center py={6} px="50px">
          <Box
            height={'550px'}
            maxW={'400px'}
            minW={'350px'}
            w={'full'}
            bg={useColorModeValue('gray.200', 'gray.900')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'2xl'}
              src={wilfrid}
              alt={'Wilfrid Schroeder'}
              mb={4}
              pos={'relative'}
            />
            <Heading fontSize={'2xl'} fontFamily={'body'} color="gray.900">
              Dr. Wilfrid Schroeder
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              Physical Scientist
            </Text>
            <Text
              textAlign={'center'}
              color={useColorModeValue('gray.700', 'gray.400')}
              px={3}
            ></Text>
            <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
              <LinkBox>
                <LinkOverlay href="https://www.nesdis.noaa.gov/">
                  <IconButton
                    aria-label="Schroeder website"
                    size="lg"
                    icon={<FaUniversity />}
                  />
                </LinkOverlay>
              </LinkBox>
              <IconButton
                aria-label="Schroeder linkedin"
                size="lg"
                icon={<FaLinkedinIn />}
                isDisabled="true"
              />
              <IconButton
                aria-label="Schroeder twitter"
                size="lg"
                icon={<FaXTwitter />}
                isDisabled="true"
              />
            </Stack>
          </Box>
        </Center>
      </SimpleGrid>
    </ChakraProvider>
  );
}

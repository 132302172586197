import React from 'react';

import Footer from './components/Footer.js';
import TeamMemberTemplate from './components/team-pages/TeamMemberTemplate.js';
//import TeamCardColabs from './components/TeamCardColabs.js';

function TeamMemberPage() {
  return (
    <div
        sx={{
      '& .scrollDiv': {
        height: 'auto',
        maxHeight: '100%',
        overflow: 'auto',
      },
    }}
  >
      <TeamMemberTemplate />
      <Footer />
    </div>
  );
}

export default TeamMemberPage;
import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Tabs,
  TabList,
  Tab,
  Box,
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const [size, setSize] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = newSize => {
    setSize(newSize);
    onOpen();
  };

  // Map paths to their corresponding tab index
  const tabPaths = ['/', '/publications', '/datasets', '/stats', '/team', '/archive'];

  useEffect(() => {
    // Set the active tab index based on the current path
    const currentIndex = tabPaths.indexOf(location.pathname);
    setActiveIndex(currentIndex !== -1 ? currentIndex : 0); // Default to Home if path doesn't match
  }, [location.pathname]);

  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Box>
          <Flex display={['none', 'none', 'flex', 'flex']}>
            <Box bg="gray.100" w="100%" p={4}>
              <Stack direction="row" justifyContent={'space-between'}>
                <Text align="left"></Text>
                <Tabs
                  variant="soft-rounded"
                  colorScheme="orange"
                  align="end"
                  index={activeIndex} // Set the active tab based on state
                >
                  <TabList>
                    <Link to="/">
                      <Tab>Home</Tab>
                    </Link>
                    <Link to="/publications">
                      <Tab>Publications</Tab>
                    </Link>
                    <Link to="/datasets">
                      <Tab>Datasets</Tab>
                    </Link>
                    <Link to="/stats">
                      <Tab>Stats</Tab>
                    </Link>
                    <Link to="/team">
                      <Tab>Team</Tab>
                    </Link>
                    <Link to="/archive">
                      <Tab>Archive</Tab>
                    </Link>
                  </TabList>
                </Tabs>
              </Stack>
            </Box>
          </Flex>
        </Box>

        <Box align="right" w="100%" p={0.15} bg="gray.100">
          <Button
            onClick={() => handleClick('xs')}
            m={4}
            size="lg"
            aria-label="Navigation Menu"
            color={'orange.500'}
            bg={'orange.100'}
            display={['flex', 'flex', 'none', 'none']}
          >
            <HamburgerIcon />
          </Button>

          <Drawer onClose={onClose} isOpen={isOpen} size={size}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton size="lg" />
              <DrawerHeader>{`Menu`}</DrawerHeader>
              <DrawerBody>
                <Stack direction="column" spacing={4}>
                  <Link to="/">
                    <Button size="lg" width="100%" colorScheme="orange">
                      Home
                    </Button>
                  </Link>
                  <Link to="/publications">
                    <Button size="lg" width="100%" colorScheme="orange">
                      Publications
                    </Button>
                  </Link>
                  <Link to="/datasets">
                    <Button size="lg" width="100%" colorScheme="orange">
                      Datasets
                    </Button>
                  </Link>
                  <Link to="/stats">
                    <Button size="lg" width="100%" colorScheme="orange">
                      Stats
                    </Button>
                  </Link>
                  <Link to="/team">
                    <Button size="lg" width="100%" colorScheme="orange">
                      Team
                    </Button>
                  </Link>
                  <Link to="/archive">
                    <Button size="lg" width="100%" colorScheme="orange">
                      Archive
                    </Button> 
                  </Link>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Navbar;

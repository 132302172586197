import React from 'react'
import {Heading, Box, ChakraProvider, theme} from '@chakra-ui/react'

function DataGridHeader() {
  return (
    <ChakraProvider theme={theme}>

    
    <Box w="100%" py={4} px={10}>
        <Heading as="h3" size="lg" textAlign="left">
        Search Datasets & User Guides
        </Heading>
    </Box>
    </ChakraProvider>

  )
}


export default DataGridHeader
import React from 'react';
import ArchiveNews from './components/ArchiveNews';
import ArchiveNewsHeader from './components/header/ArchiveNewsHeader';

function ArchivePage() {
  return (
    <div
      sx={{
        '& .scrollDiv': {
          height: 'auto',
          maxHeight: '100%',
          overflow: 'auto',
        },
      }}
    >
        <ArchiveNewsHeader />
        <ArchiveNews />

    </div>
  );
}

export default ArchivePage;
import React from 'react';
import Footer from './components/Footer.js';
import VIIRSsideBar from './components/viirs/VIIRSsideBar.js';
import VIIRSBAProducts from './components/viirs/VIIRSBAProducts.js';
import { Grid, GridItem } from '@chakra-ui/react'




function VIIRSBAPage() {
  return (
    <div
    >
      <Grid
        h='auto'
        templateRows='repeat(1, 1fr)'
        templateColumns='repeat(5, 1fr)'
        gap={4}
      >
        <GridItem rowSpan={1} colSpan={1}> <VIIRSsideBar /> </GridItem>
        <GridItem colSpan={4}> <VIIRSBAProducts /></GridItem>
      </Grid>
      <Footer />
    </div>
  );
}

export default VIIRSBAPage;
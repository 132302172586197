import React from "react"
import {
  Box,
  IconButton,
  useBreakpointValue,
  Stack,
  Heading,
  Text,
  Container,
  ChakraBaseProvider,
  theme
} from "@chakra-ui/react"
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi"
// And react-slick as our Carousel Lib
import Slider from "react-slick"


import viirsCarousel from './data/img/viirs_worldview2.png'
// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default function CaptionCarousel() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null)

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  //const top = useBreakpointValue({ base: "90%", md: "50%" })
  //const side = useBreakpointValue({ base: "30%", md: "40px" })


  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      title: "Giglio Fire Group",
      text:
        "Image Source: NASA Worldview",
      image:
        viirsCarousel
    },
    {
      title: "Giglio Fire Group",
      text:
        "Image Source: NASA Worldview",
      image:
      viirsCarousel
    },
    // {
    //   title: "First Complete Day of MODIS",
    //   text:
    //     "MODIS Atmosphere Team, NASA GSFC",
    //   image:
    //     world
    // }
    {
      title: " Giglio Fire Group",
      text:
        "Image Source: NASA Worldview",
      image:
      viirsCarousel

    }
  ]

  return (
    <ChakraBaseProvider theme={theme}>
    <Box
      position={"relative"}
      height={"400px"}
      width={"full"}
      overflow={"hidden"}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={{base: "30%", md: "40px"}}
        top={{base: "90%", md: "50%"}}
        transform={"translate(0%, -50%)"}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
        
      >
        <BiLeftArrowAlt size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={{base: "30%", md: "40px" }}
        top={{base: "90%", md: "50%"}}
        transform={"translate(0%, -50%)"}
        zIndex={2}
        onClick={() => slider?.slickNext()}
      >
        <BiRightArrowAlt size="40px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={slider => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            key={index}
            height={"6xl"}
            position="relative"
            backgroundPosition="contain"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundImage={`url(${card.image})`}
          >
            {/* This is the block you need to change, to customize the caption */}
            <Container size="container.lg" height="300px" position="relative" color="gray.200">
              <Stack
                spacing={6}
                w={"full"}
                maxW={"lg"}
                position="absolute"
                top="50%"
                transform="translate(0, -50%)"
                
              >
                <Box bg="rgba(133,133,133,0.7)" borderRadius='md'>
                <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }} >
                  {card.title}
                </Heading>
                </Box>
                
                <Text fontSize={{ base: "md", lg: "lg" }}>
                  {card.text}
                </Text>
              </Stack>
            </Container>
          </Box>
        ))}
      </Slider>
    </Box>
    </ChakraBaseProvider>
  )
}
"use client"
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
    ChakraProvider
} from "@chakra-ui/react"
import {
    FcAbout,
    FcAssistant,
    FcCollaboration,
    FcDonate,
    FcManager,
    FcGlobe
} from "react-icons/fc"

const Card = ({ heading, description, icon, pdfPath }) => {
    return (
        <Box
            maxW={{ base: "full", md: "275px" }}
            w={"full"}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={5}
        >
            <Stack align={"start"} spacing={2}>
                <Flex
                    w={16}
                    h={16}
                    align={"center"}
                    justify={"center"}
                    color={"white"}
                    rounded={"full"}
                    bg={useColorModeValue("gray.100", "gray.700")}
                >
                    {icon}
                </Flex>
                <Box mt={2}>
                    <Heading size="md">{heading}</Heading>
                    <Text mt={1} fontSize={"sm"}>
                        {description}
                    </Text>
                </Box>
                <Button variant={"link"} colorScheme={"blue"} size={"sm"} as="a" href={pdfPath} download>
                    User Guide
                </Button>
            </Stack>
        </Box>
    )
}

export default function VIIRSBAProducts() {
    return (
        <ChakraProvider>
            <Box p={4}>
                <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
                    <Heading fontSize={{ base: "2xl", sm: "4xl" }} fontWeight={"bold"}>
                        VIIRS Burned Area Products
                    </Heading>
                    <Text color={"gray.600"} fontSize={{ base: "sm", sm: "lg" }}>
                    The VIIRS Version 2 release includes a data product from the Suomi National Polar-orbiting Partnership (Suomi NPP) satellite. 
                    This release covers the mission period from March 2012 up to the leading edge.
                    </Text>
                </Stack>

                <Container maxW={"5xl"} mt={12}>
                    <Flex flexWrap="wrap" gridGap={6} justify="center">
                        <Card
                            heading={"VNP64A1"}
                            icon={<Icon as={FcGlobe} w={10} h={10} />}
                            description={
                                "The daily NASA/NOAA Suomi National Polar-orbiting Partnership (Suomi NPP) Visible Infrared Imaging Radiometer Suite (VIIRS) Burned Area (VNP64A1) Version 2 data product is a monthly, global gridded 500-meter (m) product containing per-pixel burned area and quality information. "
                            }
                            pdfPath={"/pdfs/VNP64_User_Guide_v1.1.pdf"}
                        />
                    </Flex>
                </Container>
            </Box>
        </ChakraProvider>
    )
}

import React, { useEffect, useState } from 'react';
import { Box, Text, Icon, keyframes, ChakraProvider } from '@chakra-ui/react';
import { FaArrowDown } from 'react-icons/fa';

// Pulsing animation for the arrow
const pulse = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ScrollTooltip = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false); // Hide the tooltip after 5 seconds
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <ChakraProvider>
    <Box
      position="fixed"
      top="75%" // 60% of the screen height
      right="42%" // 10% from the left
      zIndex="100"
      textAlign="center"
      pointerEvents="none" // Makes sure it doesn’t block interactions
      bgColor="orange.500"
      rounded="md"
      shadow="xl"
      p={2}
      animation={`${pulse} 1.5s infinite`}
    >
      {/* "Scroll Down" Text */}
      <Text
        fontSize="xl"
        color="orange.50"
        fontWeight="bold"
        mb={2} // Adds spacing between the text and arrow
      >
        Scroll Down for Table
      </Text>

      {/* Pulsing Arrow */}
      <Icon
        as={FaArrowDown}
        boxSize={8}
        color="orange.50"
         // Pulsing animation
      />
    </Box>
    </ChakraProvider>
  );
};

export default ScrollTooltip;
